<template>
    <div id="app" class="wrapper">
        <router-view />
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: "app",
    async mounted() {
        // await this.setConfigurations();
    },
    methods: {
        ...mapActions({
			setConfigurations: 'configuration/setConfigurations',
		}),
    }
};
</script>

<style lang="scss">
@import "reset";
    
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700&display=swap");
@import url("./assets/iconfont/material-icons.css");

html,
body {
    height: 100%;
    font-family: "Roboto Condensed", sans-serif;
}

a {
    cursor: pointer;
    outline: none;
}

.wrapper {
    display: flex;
    height: auto;
}

.after_login {
    display: flex;
    /*toggle this to flex or none*/
    width: 100%;
    height: 100%;
}

.before_login {
    display: none;
    /*toggle this to flex or none*/
    width: 100%;
    height: 100vh;
    background: #ddd;
}

input,
textarea,
label,
select {
    font-family: "Roboto Condensed", sans-serif;
}

@import "custom_common";
@import "adjustments";</style>
