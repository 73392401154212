import axios from "axios";
import Toast from "@/mixins/toast";
import router from './router.js';

let http = axios.create({
 baseURL: process.env.VUE_APP_API_URL
});

http.interceptors.request.use(
 function(config) {
   const token = localStorage.token ? localStorage.token : "";
   config.headers.Authorization = `Bearer ${token}`;
   config.headers.Accept = "application/json";
   config.headers["X-Requested-With"] = "XMLHttpRequest";
   config.headers["Content-Type"] = "application/json";
   return config;
 },
 function(err) {
   return Promise.reject(err);
 }
);

http.interceptors.response.use(
  response => {
    localStorage.last_active = Date.now();
    return response;
  },
  error => {
    if (error.response.status == 400) {
      try {
        const errormsg = error.response.data;
        try {
          errormsg.msg.errors.forEach(element => {
            Toast.error(element);
          });
        } catch (e) {
          if (errormsg.msg) {
            Toast.error(errormsg.msg);
          } else {
            Toast.error("Page not found");
          }
        }
      } catch (e) {
        Toast.error("Request failed with an error code of 400");
      }
    }else if (error.response.status == 401) {
      try {
        if (error.response.data.status_code == "token_expired") {
          localStorage.token = error.response.data.token;
          error.config.headers.Authorization = `Bearer ${
            error.response.data.token
          }`;
          return axios.request(error.config);
        } else {
          localStorage.clear();
          router.push("/");
          Toast.error(error.response.data.msg);
          return;
        }
      } catch (e) {
        localStorage.clear();
        router.push("/");
        Toast.error("Unauthorized request");
        return;
      }
    } else if(error.response.status == 500){

      if(error.response.data.msg) {
        Toast.error(error.response.data.msg);
      }
    }
    
    return Promise.reject(error);
  }
);

export default http;