export default {
    bind(el) {
      el.addEventListener('wheel', (event) => {
        event.preventDefault();
      });
    },
    unbind(el) {
      el.removeEventListener('wheel', (event) => {
        event.preventDefault();
      });
    }
  };
  